import Vue from "vue";
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        convert: 0,
        cameraData: [],
    },
    mutations: {
        convertFN(state, data) {
            state.convert = data.value
        },
        cameraDataFN(state, data) {
            state.cameraData.push(data.value)
        },
    }
})